import aktirovkaBgr from '../../assets/images/articles/bgr/06_aktirovka_bgr.png';
import aktirovka0 from '../../assets/images/articles/06_aktirovka_0.png';
import aktirovka1 from '../../assets/images/articles/06_aktirovka_1.png';
import aktirovkaMono from '../../assets/images/articles/06_aktirovka_mono.png';
import aktirovkaIcon from '../../assets/images/icons/aktirovka_icon.png';

const aktirovka = {
    id: '06',
    name: 'aktirovka',
    cat: 'symbols',
    icon: aktirovkaIcon,
    mainTitle: 'Актировка',
    chapters: [
        {
            title: 'Амундсен, одиночество и\u00A0шаманские\u00A0обряды',
            author: 'Никита Макаров',
            text: [
                '«…В связи с неблагоприятными погодными условиями занятия в школах разрешается не посещать учащимся 1–11-х классов…»',
                'Как много в этой фразе слилось для любого норильского школьника! Актировка — это и славные посиделки с друзьями вместо привычных походов на учебу, и новые фильмы, которые жадно глотаешь в часы отмененных химии и геометрии, и неизгладимое одиночество, если за окном действительно дует и морозит так, что носа на улицу не высунешь…',
                'В детстве каждая актировка воспринималась как приключение. Разве не круто тайком от родителей выбраться погулять в сильный буран, ощущая себя Амундсеном или Скоттом? Добраться до соседнего магазина — уже подвиг, тем более без собачьей упряжки!',
                'С другой стороны, мне никогда не было скучно находиться целый день дома одному. Я как-то научился сам себя развлекать и даже изредка делал домашние задания, мечтая в то же время о продлении актировки и завтра (а вдруг повезет?). Возможно, именно они привили мне такую устойчивую любовь к одиночеству.',
                'В отличие от многих учеников, я почти никогда не испытывал к школе сильной неприязни — по крайней мере в детстве. Однако возможность смотреть целыми днями кино, читать книги не по программе и просто валяться в постели вместо унылого топтания у доски — это ведь как раз то, по чему я (да и любой норильский школьник) буду скучать, вспоминая в старости 11 лет своей школьной жизни.',
                'С моими одноклассниками мы регулярно шаманили — сочиняли специальные стишки, способные зазвать, как нам тогда казалось, сильный ветер и непогоду; раскладывали по подоконникам ножницы (честно говоря, уже не помню, почему именно они использовались в детских обрядах по приманиванию актировки); договаривались всем классом не делать домашку, рассчитывая на продление незапланированных каникул. Славное было время!',
                'Но самое волнующее — это, конечно, утро. Встаешь в шесть часов, в эту непролазную темень, испытываешь… такое странное чувство — что-то среднее между веселящим волнением, азартом и страхом: да или нет, жизнь или смерть? Руки дрожат. Сердце колотится, как в первом классе. В голове мысль: а вдруг не будет фарта? Я же ничего не учил по истории, как выкручиваться стану? Реально ощущаешь себя персонажем индийского фильма, где главный герой только что поставил на кон все свое состояние…',
                'Но вот на телефон приходит сообщение от друга: «Актировка по 11-й, ура, спим!»',
                'Сегодня мы выиграли. На лице появляется блаженная улыбка, глаза закрываются, и я засыпаю с мыслью, что, конечно, лучше бы идти в школу и готовиться к ЕГЭ, но я, как Скарлетт О’Хара, подумаю об этом завтра.'
            ],
            monoImg: { src: aktirovkaMono, orient: 'monoImgHor' },
            rgbImg: { src: aktirovka1, orient: 'imgHor', order: 4 }
        }
    ],
    mainImg: aktirovka0,
    bgrImg: aktirovkaBgr
};

export default aktirovka;
