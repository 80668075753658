import skillsBgr from '../../assets/images/articles/bgr/18_skills_bgr.png';
import skills0 from '../../assets/images/articles/18_skills_0.png';
import skills1 from '../../assets/images/articles/18_skills_1.png';
import skillsIcon from '../../assets/images/icons/skills_icon.png';

const skills = {
    id: '18',
    name: 'skills',
    cat: 'locations',
    icon: skillsIcon,
    geo: { top: 22, left: 53 },
    mainTitle: 'Детская школа искусств',
    chapters: [
        {
            title: 'Здесь я играла свой первый концерт',
            author: ' Арайлым Кабылбаева',
            text: [
                'Когда я была еще совсем маленькой, мама водила меня на концерты в зал школы искусств. Я никогда не могла спокойно усидеть в кресле. Либо было скучно и хотелось сбежать, либо срывал с места переполнявший меня восторг. Иногда я считала кристаллики на огромной люстре или цветы на платье сидящей рядом женщины. Иногда не отводила взгляд от смычков, синхронно двигающихся по грифам скрипок, виолончелей и контрабасов. В один из походов в это чудесное место я поняла, что безумно хочу оказаться на сцене, играть для зрителей на музыкальном инструменте и заставлять их сердца биться чаще.',
                'Мое желание было услышано. Совсем скоро руки начали изучать гриф, учиться правильно держать скрипку и смычок. В нотной папке появились Бетховен, Моцарт, Вивальди. И вот мой первый концерт. За минуту до выхода на сцену в голове кружились ноты, руки предательски дрожали, а ноги и вовсе перестали слушаться. Но вот меня объявили, и зал зааплодировал. Кивнув аккомпаниатору головой, я начала играть. Сердце колотилось с сумасшедшей силой, но спустя тактов двадцать оно внезапно затихло, и я полностью погрузилась в музыку. Перед тем как поклониться по окончании выступления, я посмотрела в зал — несколько женщин прижимали платки к глазам. Этот момент я запомнила навсегда, и с тех пор концертный зал школы искусств является самым главным для меня местом в Норильске.'
            ],
            rgbImg: { src: skills1, orient: 'imgHor', order: 1 }
        }
    ],
    mainImg: skills0,
    bgrImg: skillsBgr
};

export default skills;
