const menu = [
    {
        id: '001',
        name: 'О проекте',
        link: 'about'
    },
    {
        id: '002',
        name: 'Места',
        link: 'locations'
    },
    {
        id: '003',
        name: 'Символы',
        link: 'symbols'
    }
];

export default menu;
