import otdelnayaBgr from '../../assets/images/articles/bgr/12_otdelnaya_bgr.png';
import otdelnaya0 from '../../assets/images/articles/12_otdelnaya_0.png';
import otdelnaya1 from '../../assets/images/articles/12_otdelnaya_1.png';
import otdelnayaMono from '../../assets/images/articles/12_otdelnaya_mono.png';
import otdelnayaIcon from '../../assets/images/icons/otdelnaya_icon.png';

const otdelnaya = {
    id: '12',
    name: 'otdelnaya',
    cat: 'locations',
    icon: otdelnayaIcon,
    geo: { top: 4, left: 85 },
    mainTitle: 'Гора Отдельная. Горнолыжка',
    chapters: [
        {
            title: 'Внутренний голос плохого не\u00A0посоветует',
            author: 'Даниил Федоров',
            text: [
                'Мое любимое место — горнолыжный комплекс «Гора Отдельная». И вот почему.',
                'В 12 лет я посмотрел клип о сноубордистах, и что-то во мне щелкнуло. Захватило. Через какое-то время сестра похвасталась, что пару раз ездила туда с друзьями. Естественно, в следующий раз я напросился с ними. Помню, в машине уснул, а когда проснулся, мы уже парковались у ГЛК. Спросонья я даже не посмотрел вверх, сразу зашел в здание, мы взяли борд, ботинки и выдвинулись к горе. Учить меня должен был парень из компании сестры — Дима. Мы стали пешком подниматься вдоль трассы.',
                'После нескольких пройденных метров я наконец посмотрел вверх. Увидев гору, ее красоту, крутизну, ее величие, я ужаснулся. Спросил со страхом: «Мы на нее поднимаемся?» Но Дима ответил: «Не боись!» — и я как-то сразу перестал переживать.',
                'Процесса обучения не помню. Собственно, его и не было. Дима просто велел проехать пройденное расстояние. Я самоуверенно рванул вниз и, преодолев метров семь, навернулся. Он поднял меня и сказал попробовать снова. Как спускаться, наставник мой не объяснил, поэтому я покатил чисто под прямым углом, а это самый настоящий скоростной спуск! Ни с чем не сравнится ощущение, когда с каждой секундой набираешь все большую скорость, адреналин и страх смешиваются внутри, ветер бьет в лицо и хочется закрыть глаза, но делать этого ни в коем случае нельзя! В голове одна мысль: ты в любой момент можешь остановиться, не переживай! Пока этот внутренний голос успокаивал меня, я вылетел с трассы и заехал бог знает куда — на целину, в сугроб. Но я даже не упал! Почти… Крепление на борде заело, пришлось звонить Диме. Он приехал, отстегнул меня и говорит: «Я тут вспомнил, что не объяснил тебе, как съезжать! Ты в норме?» У меня загорелись глаза, и я сказал, что хочу повторить!',
                'Сегодня, несясь с горы, я точно знаю три вещи. Первая: внутренний голос прав — я всегда могу остановиться. Вторая: Дима не объяснил, как ехать, но показал, как тормозить, а это важнее. Третья: на горе у меня нет никаких проблем и переживаний, кроме подсчета подъемов. Так что Отдельная с тех пор мое любимое место в Норильске, и я страшно благодарен Диме. Кстати, теперь я катаюсь намного лучше него, учу новым приемам, да и борд ему подарил — в знак благодарности за первый урок.'
            ],
            monoImg: { src: otdelnayaMono, orient: 'monoImgHor' },
            rgbImg: { src: otdelnaya1, orient: 'imgHor', order: 2 }
        }
    ],
    mainImg: otdelnaya0,
    bgrImg: otdelnayaBgr
};

export default otdelnaya;
