import museumBgr from '../../assets/images/articles/bgr/38_museum_bgr.png';
import museum0 from '../../assets/images/articles/38_museum_0.png';
import museum1 from '../../assets/images/articles/38_museum_1.png';
import museumMono from '../../assets/images/articles/38_museum_mono.png';
import museumIcon from '../../assets/images/icons/museum_icon.png';

const museum = {
    id: '38',
    name: 'museum',
    cat: 'locations',
    icon: museumIcon,
    geo: { top: 41, left: 41 },
    mainTitle: 'Музей Норильска',
    chapters: [
        {
            title: 'Как он был только моим',
            author: 'Любовь Коуба',
            text: [
                'Мое любимое место в городе — музей Норильска. В нем я провела большую часть детства, потому что там работала мама и ей часто приходилось брать меня с собой. Мне он казался настоящим замком, в котором повсюду было что-то загадочное.',
                'На первом этаже, помимо чучел животных и костюмов северян, которые я очень любила рассматривать, находится стенд Мангазея, а над ним подвешены военные самолеты и чайки. Когда не было никого из посетителей, я кружила вокруг «Мангазеи», представляя себя то птицей, то самолетом. Еще я любила смотреть вниз с галереи второго этажа, где на балконе выставляли фото и живопись. А как было классно, когда мама включала мультфильмы в телевизоре, по которому крутили видеоархивы, дабы я не топотала по всему музею и не сносила экспонаты! На третьем этаже, где геология, развитие Норильского промышленного района, образцы руд и металлов, добываемые «Норникелем», бегать особо не получалось: там стояли стулья и было страшно одной в таком большом зале, поэтому я передвигалась по нему аккуратно. При этом не раз выступала на маленькой сцене — выбегала к ее краю и подпевала ансамблям. За сценой мое любимое место: там находился ключ от города, который я мечтала… прибрать.',
                'Еще я пряталась за столбами, на которых стояли макеты будущих зданий Норильска, и играла, будто ходя по домам, как какой-нибудь Кинг-Конг. А еще на третьем этаже можно посмотреть, как раньше жили люди, какая была у них изба, мебель, одежда. Я представляла себя на месте этих хозяев, придумывала, кем я буду — боярской дочерью или крестьянской внучкой. И каждый раз останавливалась на том, что мне пора бежать, а то скоро закроют зал и меня никто не найдет.',
                'Еще я часто спускалась в подвал. Там работал потрясающий мастер — делал макеты судов для выставок. Он дарил мне конфетки, а я в ответ помогала ему, подавая маленькие детальки для его больших кораблей. И удивлялась, насколько он делал все точно, сам пилил, красил, собирал и не просил никогда помощи.',
                'Сейчас мама уже не работает в музее, но я, приходя туда, всегда вспоминаю, что происходило со мной в каждом его уголке…'
            ],
            monoImg: { src: museumMono, orient: 'monoImgNormal' },
            rgbImg: { src: museum1, orient: 'imgHor', order: 3 }
        }
    ],
    mainImg: museum0,
    bgrImg: museumBgr
};

export default museum;
