import otdyhBgr from '../../assets/images/articles/bgr/10_otdyh_bgr.png';
import otdyh0 from '../../assets/images/articles/10_otdyh_0.png';
import otdyh1 from '../../assets/images/articles/10_otdyh_1.png';
import otdyhMono from '../../assets/images/articles/10_otdyh_mono.png';
import otdyhIcon from '../../assets/images/icons/otdyh_icon.png';

const otdyh = {
    id: '10',
    name: 'otdyh',
    cat: 'locations',
    icon: otdyhIcon,
    geo: { top: 38, left: 92 },
    mainTitle: 'База отдыха на Вальковском шоссе',
    chapters: [
        {
            title: 'Спасение на «Семи ветрах»',
            author: 'Диана Еськова',
            text: [
                'Летом норильчане с удовольствием выезжают отдыхать на многочисленные турбазы, расположенные в основном по сторонам Вальковского шоссе. Там много озер, некоторые хорошо прогреваются, и в них можно купаться. Мы часто ездили в одно такое место — очень важное для нашей семьи. Там произошла первая встреча моих родителей. Мой папа — обычный парень из деревни, который в детстве вообще не бывал в городах. Он приехал в Норильск больше четверти века назад вместе со своим другом. Мама родилась в Игарке, но вскоре ее семья переехала сюда.',
                'Однажды летом мама с друзьями поехала отдыхать на одно из озер недалеко от Норильска — туристическую базу «На семи ветрах». В тот необыкновенно жаркий день папа тоже отправился со своим другом искупаться в том же озере.',
                'Спуститься к воде там можно было по специально уложенным доскам. И вот мама, никого не предупредив, отправилась к берегу. Надо сказать, ей обычно «по жизни везет», вот и в тот раз она поскользнулась на мокрой доске и грохнулась в воду. Папа, находившийся неподалеку от берега, увидел это и мгновенно прыгнул в озеро спасать маму, думая, что она могла удариться и захлебнуться. Он вытащил ее из воды и понес к друзьям. Все были в ужасе, поначалу не знали, что делать. Но вскоре оказалось, что с ногой ничего серьезного не случилось — просто сильный ушиб. Мама поблагодарила папу, а уже на следующий день поняла, что влюбилась в своего спасителя! Вот так началась любовная история моих родителей.',
                'К сожалению, турбаза сгорела через несколько лет после этих событий, но мы все равно каждую весну и осень ездим в это памятное место отдыхать. Пока папа готовит мясо и картофель на костре, я с мамой собираю ягоды и цветы. Еще папа учит нас стрелять по мишеням (сам он научился в    армии), это очень интересно, и мне кажется, что у меня получается неплохо. А летом мы иногда купаемся в том самом озере, так как вода там нагревается до 20 градусов. Родители каждый раз вспоминают, что происходило здесь почти 30 лет назад, и на их лицах отражается необыкновенное счастье!',
                'Думаю, что, оказавшись в Норильске (сейчас я учусь в Москве на журналиста), я тоже буду приезжать сюда с друзьями, а может, и со своей будущей семьей!'
            ],
            monoImg: { src: otdyhMono, orient: 'monoImgNormal' },
            rgbImg: { src: otdyh1, orient: 'imgHor', order: 3 }
        }
    ],
    mainImg: otdyh0,
    bgrImg: otdyhBgr
};

export default otdyh;
