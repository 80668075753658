import kryshiBgr from '../../assets/images/articles/bgr/30_kryshi_bgr.png';
import kryshi0 from '../../assets/images/articles/30_kryshi_0.png';
import kryshi1 from '../../assets/images/articles/30_kryshi_1.png';
import kryshi2 from '../../assets/images/articles/30_kryshi_2.png';
import kryshiIcon from '../../assets/images/icons/kryshi_icon.png';

const kryshi = {
    id: '30',
    name: 'kryshi',
    cat: 'symbols',
    icon: kryshiIcon,
    mainTitle: 'Крыши',
    subTitle: 'Глядя на городскую суету сверху…',
    chapters: [
        {
            title: 'Время здесь кажется слишком быстротечным…',
            author: 'Софа Дорошкевич',
            text: [
                'Чердак. Скрип двери и сиплый выдох настила на полу, колющая глаза темнота и пыль, что вот-вот опутает легкие, и придется ненавязчиво чихнуть. Дальше, пробираясь через тернии труб и стекловаты, мы попадаем в одно из самых красивых мест города за полярным кругом. Крыша дома номер 78 давно стала моим другом, ведь здесь не один раз были впервые произнесены вслух важнейшие слова, признаны и сразу опровергнуты некоторые истины. На этих железных листах день за днем начинались и заканчивались книги, иногда отсюда были слышны горестные возгласы или безудержный смех. Ну и конечно, у бордовой крыши не отнять и ее физической красоты. Когда находишься там, на высоте девяти этажей от земли, все внизу становится чуточку меньше.',
                'Глядя на городскую суету сверху, боишься не упасть, а чего-то не успеть, не сказать, забыть или потерять. Здесь время кажется слишком быстротечным, а дела, на которые тратят его люди, скучными и бесполезными.',
                'Именно здесь, на крыше крайнего дома по  Талнахской, мне удалось почувствовать себя живой.'
            ],
            rgbImg: { src: kryshi1, orient: 'imgHor', order: 2 }
        },
        {
            title: 'Точка для созерцаний и размышлений',
            author: 'Всеволод Ерчуков',
            text: [
                'В Норильске есть одна крыша в 12-этажке. Я люблю бывать там и зимой, которая длится у нас девять месяцев в году, и в летние ночи и вечера. Люблю сидеть на ней утром, когда воздух наполняется удивительным запахом свежести и чего-то необычного (ну, конечно, не когда ветер со стороны комбината), — такого запаха нет ни в какое другое время суток. Также в этом месте во время перехода с зимы на лето (да-да, у нас не весна, а переход) можно наблюдать восход и закат в их истинном виде. Почему в истинном? Да потому что у нас во время переходного сезона, в отличие от зимы и лета, есть и закаты, и рассветы, и такая первозданная красота на небе. Солнце освещает горы с ледяными покровами, лесотундра начинает цвести… Да и вообще здорово смотреть на родной город с высоты.',
                'Я впервые попал на эту крышу в далеком 2009 году, когда мне было всего шесть лет и я любил (и до сих пор люблю) шариться по таким местам. Конечно, со старшими мальчиками. Помню, на чердаке, откуда мы вылезли на крышу, было очень тепло и темно. Практически наощупь огибали какие-то трубы, вспугнули множество гнездившихся там голубей и наконец обнаружили лестницу и люк, который оказался не заперт. Выбравшись на воздух, мы увидели рассвет. Небо постепенно озарялось желтым свечением, и вскоре выглянуло солнце, а воздух наполнился тем самым особым ароматом, о котором я уже упомянул. Мы любовались этим зрелищем часа три, а потом ушли гулять. С тех пор я постоянно возвращаюсь туда. Мне кажется, в каждом городе должна быть такая особенная крыша для созерцаний и размышлений.'
            ],
            rgbImg: { src: kryshi2, orient: 'imgVert', order: 1 }
        }
    ],
    mainImg: kryshi0,
    bgrImg: kryshiBgr
};

export default kryshi;
