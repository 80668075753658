import holodBgr from '../../assets/images/articles/bgr/62_holod_bgr.png';
import holod0 from '../../assets/images/articles/62_holod_0.png';
import holod1 from '../../assets/images/articles/62_holod_1.png';
import holodIcon from '../../assets/images/icons/holod_icon.png';

const holod = {
    id: '62',
    name: 'holod',
    cat: 'symbols',
    icon: holodIcon,
    mainTitle: 'Холод',
    chapters: [
        {
            title: 'Он сформировал меня как личность',
            author: 'Даниил Валантырь',
            text: [
                'Холод. Он всегда здесь. На улице. На душе, на лицах. Или нет? Когда с ноября по апрель температура не поднимается выше минус 20 градусов, это здорово действует на психику. Мороз, ветер, полярная ночь и домоседство становятся четырьмя всадниками апатии и тоски, с которыми надо как-то бороться. Ты ведь все равно хочешь гулять, развиваться, встречаться с друзьями. Ты хочешь жить. Мы все проходим через это. И я прошел. В минус 40 я ходил на тренировки по борьбе, гулял, занимался в телестудии «Перемена», фотографировал, а порой и сидел дома, читая книги, делая уроки или играя на гитаре.',
                'Пожалуй, я стал чем-то похож на этот холод. Точнее, именно холод сформировал многие качества моего характера. Я спокойный, рассудительный и умею расставлять приоритеты, ведь в мороз не будешь растрачивать энергию по пустякам. Я предусмотрительный и практичный. Для обретения этих качеств достаточно один раз забыть перчатки дома, а потом встретиться с обжигающей ледяной ручкой двери подъезда. Думаю, что я вполне стойкий и способен преодолевать трудности не только в виде экстремально низких температур — как многие здесь. Стужа в Арктике закаляет людей и духовно. Холод стал моей стихией, которую я научился использовать.',
                'И этот холод нас… греет! Обжигает душу, стимулирует, обязывает ее трудиться. Несмотря на все сложности, на эмоциональный дефицит, вызванный норильским днем сурка, многие здесь наполняют свою жизнь творчеством — рисуют, фотографируют, пишут песни, танцуют, берут от жизни максимум. Идешь по ветру при минус 40 и декламируешь Маяковского! Холод разжег огонь в сердце, подталкивающий меня писать стихи, сочинять песни под гитару, ходить на журналистику, чтобы дискутировать там о важном и создавать тексты. Другим здесь быть просто не получается.',
                'Холод научил меня ценить нечастые встречи с друзьями. Однажды ко мне приехал старый друг, которого я не видел больше года. Несмотря на лютый мороз и бушующий ветер, я, он и еще один давний наш товарищ бродили по одиноким улицам допоздна, и воспоминания об этом грели всех троих еще долго.',
                'В жуткую стужу я впервые побывал в театре с папой, после чего мы долго не  могли попасть домой из-за отсутствия таксистов (маршрутки уже не ездили). Никогда мороз не был препятствием для свиданий с девушкой… Я вырос с холодом, и, если бы мое детство прошло в более теплом климате, то я был бы другим. Что говорить — я даже родился в декабре!',
                'Да, я не останусь здесь навсегда. Перееду в места с гораздо более теплым климатом, где в марте минус 10, а не 40, а снег появляется от силы на два месяца. Но я все равно оставлю этот горячий холод в себе, найду его в других, буду вспоминать, как мы с друзьями с обмороженными щеками залезли в заброшенное здание в 50-градусный мороз, чтобы сыграть в догонялки и отогреться. Это скоро произойдет, но я никогда не забуду значение и смысл всего того, что дал мне холод этого города.'
            ],
            rgbImg: { src: holod1, orient: 'imgVert', order: 4 }
        }
    ],
    mainImg: holod0,
    bgrImg: holodBgr,
    images: [holod1]
};

export default holod;
