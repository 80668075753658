import lamaBgr from '../../assets/images/articles/bgr/44_lama_bgr.png';
import lama0 from '../../assets/images/articles/44_lama_0.png';
import lama1 from '../../assets/images/articles/44_lama_1.png';
import lamaMono from '../../assets/images/articles/44_lama_mono.png';
import lamaIcon from '../../assets/images/icons/lama_icon.png';

const lama = {
    id: '44',
    name: 'lama',
    cat: 'locations',
    icon: lamaIcon,
    geo: { top: 10, left: 94 },
    mainTitle: 'Озеро Лама',
    chapters: [
        {
            title: 'Никого — кроме меня и природы',
            author: 'Изабелла Чупрова',
            text: [
                'У Норильска потрясающие окрестности! Мое любимое место — озеро Лама, расположенное на пути к плато Путорана.',
                'Я прекрасно помню день, когда впервые приплыла туда. Было прохладно, на горах виднелось немного снега, а впереди была только кристально чистая вода. Мы разобрали вещи и заняли маленькую беседку, из которой открывался чудесный вид. В этом месте не было телефонной сети, а это означало, что ближайшие три дня я смогу забыть все внешние проблемы и наслаждаться воздухом, который не испорчен предприятиями. Я налила кружку чая и села на берегу, укутавшись в курточку. Мне было тепло не только физически, но на душе. Максимально комфортно и спокойно.',
                'Следующие пару дней я провела активно: поднялась в горы, побывала на водопадах, собирала грибы и даже нашла дикую малину, которая была ужасно колючей, но сладкой. Это были прекрасные дни, я загадывала желания, я смеялась, я пела и не думала ни о чем, ведь в тот момент не существовало никого, кроме меня и природы — мы слились в одно целое, не замечая ни людей, ни времени.',
                'Я обязательно вернусь сюда еще не   один раз — в то место, где жизнь кажется чистой и волшебной.'
            ],
            monoImg: { src: lamaMono, orient: 'monoImgNormal' },
            rgbImg: { src: lama1, orient: 'imgHor', order: 2 }
        }
    ],
    mainImg: lama0,
    bgrImg: lamaBgr
};

export default lama;
