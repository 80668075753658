import naberezhBgr from '../../assets/images/articles/bgr/60_naberezh_bgr.png';
import naberezh0 from '../../assets/images/articles/60_naberezh_0.png';
import naberezh1 from '../../assets/images/articles/60_naberezh_1.png';
import naberezhMono from '../../assets/images/articles/60_naberezh_mono.png';
import naberezhIcon from '../../assets/images/icons/naberezh_icon.png';

const naberezh = {
    id: '60',
    name: 'naberezh',
    cat: 'locations',
    icon: naberezhIcon,
    geo: { top: 75.5, left: 34 },
    mainTitle: 'У набережной Урванцева',
    chapters: [
        {
            title: 'Научиться держать равновесие…',
            author: 'Никита Макаров',
            text: [
                'Это место находится рядом с набережной Урванцева, неподалеку от плавательного бассейна.',
                ' Пройти туда легче через надземный пешеходный переход, который рядом с ДТДМ (Домом творчества детей и молодежи). Там очень чисто, весь склон, отделяющий тротуар от самой улицы, покрыт растительностью. Отсюда открывается отличный вид на озеро, дорогу, горы, заводы, даже немного видно мечеть. Но пейзаж — не главное. Для меня это место значимо, потому что тут я когда-то научился кататься на велосипеде.',
                'Я тогда ходил в начальную школу, и мне очень не нравилось, что я до сих пор не умею ездить на велике. В тот день было очень тепло и солнечно, мы пришли сюда с родителями, кроме нас никого не было, и мы с папой принялись наворачивать круги вокруг ближайшего здания, а мама старалась снимать нас на камеру. Мы провели тут весь день. Даже не помню, научил ли меня папа держать равновесие или я все же шел домой пешком, но день этот получился абсолютно чудесным.',
                'Я и сейчас иногда бываю там летом — всегда один, — ощущая спокойствие и умиротворение. И это единственное в Норильске место, которое напоминает мне о беззаботном и светлом детстве.'
            ],
            monoImg: { src: naberezhMono, orient: 'monoImgNormal' },
            rgbImg: { src: naberezh1, orient: 'imgHor', order: 2 }
        }
    ],
    mainImg: naberezh0,
    bgrImg: naberezhBgr
};

export default naberezh;
