import shmidtBgr from '../../assets/images/articles/bgr/14_shmidt_bgr.png';
import shmidt0 from '../../assets/images/articles/14_shmidt_0.png';
import shmidt1 from '../../assets/images/articles/14_shmidt_1.png';
import shmidtMono from '../../assets/images/articles/14_shmidt_mono.png';
import shmidtIcon from '../../assets/images/icons/shmidt_icon.png';

const shmidt = {
    id: '14',
    name: 'shmidt',
    cat: 'locations',
    icon: shmidtIcon,
    geo: { top: 58, left: 55 },
    mainTitle: 'Гора Шмидта',
    chapters: [
        {
            title: 'Прекрасная и жуткая',
            author: 'Полина Гришина',
            text: [
                'Норильск не отличается разнообразием природы, и вряд ли здесь можно найти множество мест с красивым видом.',
                'Я расскажу об одном — о горе Шмидта.',
                'Бываю я там нечасто, но эмоций после визита предостаточно. Признаюсь честно: впечатления двоякие.',
                'С одной стороны, это гора с чудесными пейзажами, ручьями и растениями, место, безусловно, красивое. Но стоит немного углубиться в историю, и по коже сразу бегут мурашки, а красота становится ужасающей.',
                'Сказать, что здесь атмосферно, — не сказать ничего. Фактически это целый заброшенный город. Разрушенные здания, обвалившиеся столбы, рельсы… Ранее по одному из склонов горы проходила железнодорожная ветка для перевозки угля, ныне, понятно, заброшенная. Как известно, город строился заключенными Норильлага. Рельсы ворочали вручную, причем часто это делали женщины. Из любопытства я пробовала протащить хотя бы часть рельса с помощью папы, но нам не удалось его даже приподнять. А люди поднимали, таскали — и все это в условиях страшного ветра и дикого холода. Как им это удавалось, я не могу понять до сих пор. Теперь повсюду раскидан мусор, появившийся, скорее всего, когда постройки утратили надобность. Порой можно встретить покрытые пылью кости, размером напоминающие человеческие. Кому они принадлежат — неизвестно.',
                'Несмотря на столь пугающую историю, место поражает своей красотой и загадочностью, ведь гора соединяет промышленный город и суровую природу воедино. Норильск тут словно на ладони. Добраться до смотровой площадки не так просто, но, поверьте, оно стоит того. Именно здесь человек может по-настоящему понять, насколько плотно связаны прошлое и настоящее.',
                'И кстати, многие выпускники норильских школ наутро после бала совершают восхождение на гору Шмидта — традиция такая…'
            ],
            monoImg: { src: shmidtMono, orient: 'monoImgNormal' },
            rgbImg: { src: shmidt1, orient: 'imgVert', order: 3 }
        }
    ],
    mainImg: shmidt0,
    bgrImg: shmidtBgr
};

export default shmidt;
