import kombinatBgr from '../../assets/images/articles/bgr/28_kombinat_bgr.png';
import kombinat0 from '../../assets/images/articles/28_kombinat_0.png';
import kombinat1 from '../../assets/images/articles/28_kombinat_1.png';
import kombinatMono from '../../assets/images/articles/28_kombinat_mono.png';
import kombinatIcon from '../../assets/images/icons/kombinat_icon.png';

const kombinat = {
    id: '28',
    name: 'kombinat',
    cat: 'locations',
    icon: kombinatIcon,
    geo: { top: 6, left: 4 },
    mainTitle: 'Комбинат, «Надежда», плавильный цех',
    chapters: [
        {
            title: 'Мой папа — супергерой!',
            author: 'Арина Бабыкина',
            text: [
                'В детстве меня, как и многих детей, родители приводили к себе на работу. Из маминого школьного класса английского языка я не вылезала, а вот папа свое рабочее место показывать не спешил. Мое семилетнее сознание этому факту всячески сопротивлялось. Любопытство сводило с ума, ведь рассказы о плавильном цехе звучали дома постоянно…',
                '«Эх, дочь, знала бы ты, где трудится твой отец! У нас в цеху летом температура под 50 градусов! А как опасно стоять на выдаче штейна и наблюдать, как огромная раскаленная лавина температурой 1200 градусов течет по желобу в ковш, обдавая жаром до самых костей… Но видела бы ты, доченька, как это красиво, когда брызги тысяч разноцветных искр пролетают перед глазами и останавливают время своим огненным величием!»',
                'Папа уже больше 20 лет трудится на Надеждинском металлургическом заводе и ни разу не задумывался о смене деятельности. Его профессия — плавильщик — покрыта ореолом романтики. Поэтому для меня с детства отец был круче любого супергероя из комиксов, ведь он почти каждый день сражался с раскаленной лавиной! И как же мне хотелось хоть одним глазком увидеть это таинственное зрелище.',
                'Однажды моя мечта сбылась. Как-то раз папа осчастливил меня заветной фразой: «Ну что, Аришон, дождалась, на выходных поедем ко мне на завод!» Квартиру оглушил крик восторга, а через пару дней я уже заходила в двери НМЗ. Это был другой мир — пугающий и восхищающий одновременно. Помню, как мы минут двадцать шли вдоль длинных коридорных лабиринтов к цеху. Там практически не было людей, отчего становилось еще страшнее. Ощущался странный непривычный запах газа. Плакаты о соблюдении техники безопасности обостряли чувство тревоги. Сказочные рассказы папы стали суровой промышленной реальностью.',
                'По правилам техники безопасности на меня натянули спецформу, на личико надели огромный респиратор, а на папе был еще и щиток.',
                '— Пап, а почему у тебя еще и эта штука на глазах?',
                '— Брызги металла летят в разные стороны, я могу обжечь глаза, а щиток меня выручит, Аришенька…',
                'После этого, естественно, щиток начала требовать и я, но папа успокоил меня, объяснив, что от самого штейна я буду находиться далеко.',
                'И вот момент Х настал. Робкими шагами я зашла в цех — в мир, где каждый день по восемь часов трудятся сотни супергероев, и один из них — мой папа! (Вспомните экскурсию в мультфильме «Университет монстров»!) Сердце готово было выпрыгнуть из груди, когда перед глазами возник огромный ковш, из которого лился раскаленный металл, рассыпая по всему цеху мириады искр. Страх испарился, и я просто наблюдала происходящее на моих глазах чудо… Параллельно папа объяснял технологию выдачи штейна, рассказывал о своих обязанностях и целях получения металла.',
                'Придя после этого в школу, маленьким супергероем чувствовала себя и я. На всех переменках одноклассники безостановочно слушали мои рассказы о папиной работе и завидовали, что мне удалось побывать внутри этого опасного места.',
                'Про папину работу супергероем я по сей день рассказываю с таким же детским восторгом, но уже с взрослой гордостью и осознанием, насколько же тяжел его труд. Наш Норильск действительно богат умелыми и отважными людьми, которые ежедневно преданно служат городу и стране. Да, предприятия загрязняют атмосферу и с чистым воздухом в Норильске большие проблемы. Но ведь России нужны никель, медь, кобальт, палладий… В конце концов, закрыл же «Норникель» старый никелевый завод — самое неэкологичное и вредное производство. Но закрой весь комбинат — города не будет, а ведь за ним судьба тысяч рабочих, которые десятилетиями честно делают свое дело. Среди них и мой папа. Моя гордость. Мой главный пример. Мой супергерой.'
            ],
            monoImg: { src: kombinatMono, orient: 'monoImgHor' },
            rgbImg: { src: kombinat1, orient: 'imgHor', order: 7 }
        }
    ],
    mainImg: kombinat0,
    bgrImg: kombinatBgr
};

export default kombinat;
