import mohnatyeBgr from '../../assets/images/articles/bgr/36_mohnatye_bgr.png';
import mohnatye0 from '../../assets/images/articles/36_mohnatye_0.png';
import mohnatye1 from '../../assets/images/articles/36_mohnatye_1.png';
import mohnatye2 from '../../assets/images/articles/36_mohnatye_2.png';
import mohnatye3 from '../../assets/images/articles/36_mohnatye_3.png';
import mohnatyeIcon from '../../assets/images/icons/mohnatye_icon.png';

const mohnatye = {
    id: '36',
    name: 'mohnatye',
    cat: 'symbols',
    icon: mohnatyeIcon,
    mainTitle: 'Мохнатые полярники',
    subTitle: 'Ну как без них на севере прожить?',
    vrezka: 'Норильчане любят животных. Не сказать, что в любой городской квартире обитает собака или кошка (мышь, попугай, шиншилла), но уж  в каждой второй — точно. Вместе с человеком они переносят все тяготы заполярной жизни — морозы, ветра, загазованность атмосферы… А еще — многочасовые перелеты, когда люди отправляются в отпуска и берут их с собой. Спасибо, конечно, хозяевам, что не оставляют на соседку бабу Маню, но и путешествовать по воздуху среди чемоданов в багажном отделении или в малюсенькой клетке-переноске тоже не компот! Приходится поскуливать, потявкивать, отчаянно мяукать, но это не сильно помогает. И они терпят. Потому что настоящие полярники! Только мохнатые.',
    chapters: [
        {
            title: 'Бенгальский тигренок',
            author: 'Елизавета Цветкова',
            text: [
                'Семь лет назад в моей семье появился комочек счастья, излучающий тепло. Родители принесли малюсенького котенка в большой черной шапке, и тогда я даже не поняла, кто это был. Я недовольно вздохнула, думая, что они купили очередных аквариумных рыбок — не погладишь, не поговоришь… Но это была кошка! С тех пор жизнь нашей семьи перевернулась с ног на голову и наполнилась яркими красками. Назвали котенка Мася.',
                ' История ее жизни драматична. Родители забрали ее из грязной вонючей квартиры, заполненной разными видами собак и кошек. Из-за ужасных условий у нее имелись большие проблемы со здоровьем. Мы долго возили Масю по ветеринарным клиникам, делали прививки и даже операции. Сейчас ее здоровью ничего не угрожает. Мася очень энергичная, носится по дому, роняя на своем пути абсолютно все. Особенно она любит побеситься ночью. В интернете пишут, что кошки бенгальской породы очень спокойны, обожают детей и купание. У нас все наоборот! Мася — бунтарь и агрессор, она ненавидит воду и постоянно кого-нибудь кусает. Всякий раз, когда кто-то проходит мимо, она сначала затаивается, потом крадется следом и неожиданно нападает сзади, цепляясь мертвой хваткой. (Все-таки родственник тигра, а может, сказывается тяжелое детство.) Но уже через пять минут Мася становится ласковой и милой.',
                'Правда, это только дома она бойкая. На улице во время прогулок кошка обычно зашуганная и боится громких звуков. Помню, мы вывели ее гулять зимой, она впервые увидела снег и аж хвост поджала — так была поражена!',
                'Несмотря на непростой характер, я очень люблю своего домашнего питомца и не представляю свою жизнь без Маси.'
            ],
            rgbImg: { src: mohnatye1, orient: 'imgVert', order: 3 }
        },
        {
            title: 'Любитель огурцов',
            author: 'Софья Кононова',
            text: [
                'Эту наглую пушистую морду зовут Финик. Он йоркширский терьер. В четыре месяца прежние хозяева по халатности сломали ему обе задние лапы и оставили в ветеринарной клинике. Мама, которой я прожужжала все уши, прося завести собаку, была очарована щенком и забрала его оттуда в наш дом. Сейчас с лапами все отлично, а вот характер у песика сложный. Например, он категорически не желает ничему учиться.',
                'Зато нас он любит и оберегает! Финик всегда на страже, никого не пропустит мимо нашей двери, не наградив грозным тявканьем. Многие соседи свято уверены, что у нас живет волкодав. Пришедших гостей Финик как истинный телохранитель ни за что не подпустит к хозяйке без тщательной проверки, сопровождаемой предостерегающим рыком. С собаками отношения у него совсем не клеятся — настолько асоциального существа я не видела даже среди людей.',
                'Зато свободолюбия ему не занимать. Каждый раз, уходя из дома, я вынуждена проводить целый ритуал, чтобы не дать ему выскочить и не носиться потом за ним по лестнице.',
                'Финик безумно любит кисломолочные продукты и… огурцы! Едва учуяв соответствующий запах, несется по квартире, сбивая все на своем пути. Также он обожает почивать в наших кроватях. Не успеешь занять свое место, считай, придется спать стоя, потому что за право возлежать на мягкой подушке он будет бороться не на жизнь, а на смерть.',
                'И все же я просто обожаю это вредное существо!'
            ],
            rgbImg: { src: mohnatye2, orient: 'imgVert', order: 4 }
        },
        {
            title: 'Его усатое величество',
            author: 'Анастасия Чернышова',
            text: [
                'Уже четыре года в нашем доме живет серый кот Барсик, главный пушистый буржуй семьи. Обрели мы его случайно. Ходили как-то в тундру за ягодой, и возле гаражей прямо мне под ноги выкатился котенок. Грязный и явно голодный. Ну как было оставлять его на произвол судьбы?!',
                'Сегодня кот — полноправный член семьи. В обязанности Барсика входит контроль за каждым событием в своем двухкомнатном царстве: его всегда оставляют за главного, когда мама уходит по делам. У Барсика свой рацион: в семь утра он завтракает нарезанным кубиками мясом, в 12 обедает печенью, ужин всегда в семь вечера — тоже печенка. Мама называет его Сметанкой и Вашим Величеством. Она его обожает и порой извиняется перед котом за один вечер больше, чем передо мной за всю мою жизнь, — настолько он важная персона! Если Барсик начинает царапать обои, мама его, конечно, наказывает, но потом опять-таки просит прощения и сокрушается, что обидела эти пушистые щечки.',
                'Барсик любит играть в прятки. Летом он периодически забивается во всякие щели в пределах нашего двора и сидит как партизан. Зовешь его, зовешь — никакой реакции! Мол, захотите, так сами найдете! Находим — что ж делать! Мы его очень любим.'
            ],
            rgbImg: { src: mohnatye3, orient: 'imgVert', order: 2 }
        }
    ],
    mainImg: mohnatye0,
    bgrImg: mohnatyeBgr,
    photoAuthor: 'авторы'
};

export default mohnatye;
