const creators = [
    { role: 'Главный редактор', name: 'Игорь Попов' },
    { role: 'Выпускающий редактор', name: 'Лада Мягкова' },
    { role: 'Художники-иллюстраторы', name: 'Наталья Олтаржевская, Арина Лепешева (Медиашкола в Заполярье, Норильск) ' },
    {
        role: 'Тексты',
        name: 'Выпускники Медиашколы Игоря Попова в Заполярье, выпуски 2016–2021 годов'
    },
    {
        role: 'Фотографии',
        name: 'Галина Вэнго, Даниил Валантырь (Медиашкола в Заполярье, Дудинка, Норильск), Игорь Попов, из архива пресс-службы «Норникеля» и медиакомпании «Северный город»'
    },
    { role: 'Дизайн', name: 'Ирина Фуфаевская' },
    { role: 'Цветокоррекция, верстка, препресс', name: 'Олег Кузнецов' },
    { role: 'Корректор', name: 'Наталья Коннова' },
    { role: 'Разработка и поддержка сайта', name: 'Дмитрий Баулин' },
    {
        role: 'Аудиотрек',
        name: 'Песня «Необитаемый город» Зои Ященко и группы «Белая Гвардия»'
    }
];

export default creators;
