import taxiBgr from '../../assets/images/articles/bgr/56_taxi_bgr.png';
import taxi0 from '../../assets/images/articles/56_taxi_0.png';
import taxi1 from '../../assets/images/articles/56_taxi_1.png';
import taxi2 from '../../assets/images/articles/56_taxi_2.png';
import taxiIcon from '../../assets/images/icons/taxi_icon.png';

const taxi = {
    id: '56',
    name: 'taxi',
    cat: 'symbols',
    icon: taxiIcon,
    mainTitle: 'Такси',
    subTitle: 'Психологи и коучи',
    vrezka: 'Такси для норильчан намного актуальнее, чем для жителей всех остальных российских городов.',
    chapters: [
        {
            title: 'Кино и страшные истории',
            author: 'Арина Мышева',
            text: [
                'Говорят, люди тут едут даже до соседнего дома. Преувеличение? Может, и нет. Когда на улице минус 50, да еще ледяной ветер валит с ног (посмотрите видео на YouTube), преодолеть 100 мет­ров — все равно что добраться пешком до Северного полюса! Хочешь не хочешь — вызываешь машину. И кстати, местные таксисты всегда доставляют не только до здания, но и до нужного подъезда, номер которого спрашивают заранее. Это традиция — зачем людей морозить?',
                'А еще они за пять минут расскажут вам историю своей жизни, объяснят, почему переехали сюда из Красноярска («Красноярск — дыра, а здесь можно деньги заработать!»), пожалуются на тещу, похвастаются дочерью, которая учится в самом Питере, прокомментируют действия как минимум трех участников дорожного движения («Вот куда прет этот болван?!») и дадут подробный прогноз погоды на завтра.',
                'Музыкальные пристрастия у них обычно напрямую зависят от транспортного средства. В хороших иномарках чаще звучит рок, иногда джаз и даже симфоническая музыка, в отечественных авто — почти всегда русский шансон. Некоторые норильские драйверы умудряются, крутя баранку, смотреть кино на смартфонах или планшетах! Иногда они даже эмоционально комментируют происходящее на экране: «Да бросай ты ее на фиг, зачем такая шалава нужна!»',
                'Про сильный мороз веско говорят: «Морозяка». Обожают травить байки-страшилки. «Поехали мужики компанией на охоту и попали в сильную пургу. Один захотел по малой нужде. Вышел из “Трэкола”, пару шагов от дороги сделал и заблудился. Остальные его искали, МЧС вызвали, но нашли только через три месяца, как снег сошел, песцами объеденного…»',
                'Старожилы знают все кочки и ухабы по дороге к аэропорту Алыкель и заранее снижают скорость. Особо вежливые, если пассажир на заднем сиденье все же подлетел и боднул головой крышу, извиняются: «Ой, простите, это сегодня ночью намело!»',
                'Почти все таксисты либо раньше работали в «Норникеле», либо и сейчас трудятся там, подрабатывая извозом. Кто постарше, рассказывают, что вот-вот уедут на материк насовсем и уже купили домик в Геленджике.',
                'Но главное — водят они в большинстве своем уверенно и быстро. За что им и спасибо!'
            ],
            rgbImg: { src: taxi1, orient: 'imgHor', order: 5 }
        },
        {
            title: 'Покоритель женских сердец',
            author: 'Кирилл Женовачёв',
            text: [
                'Такси я пользуюсь часто. Сначала к репетитору, потом к другому репетитору, затем на танцы. И так почти всю неделю. Я не жалуюсь. Наоборот, это поддерживает быстрый темп жизни. А таксисты обычно не дают мне скучать.',
                'Однажды я возвращался с занятий. Сначала мы ехали в тишине, а потом водитель начал расспрашивать меня о жизни. У таксистов, как я заметил, есть некий набор вопросов, с которых можно начать разговор. Например: «Чем занимаешься?», «Есть ли девушка?», «Куришь?» и так далее. Обычно достаточно фразы «я занимаюсь танцами», чтобы мы всю поездку болтали о них. Шоферы всегда говорят: «Это очень круто», что, конечно, не может не радовать.',
                'Вернемся к истории. Этого таксиста в первую очередь заинтересовало то, что я занимаюсь парными танцами. Он сразу же спросил: «Встречаешься с партнершей?» Я ответил, что нет. Водитель был в замешательстве. И тут началось самое интересное.',
                'Этот дядя начал рассказывать и показывать в телефоне, что у него куча девушек, которые к нему неравнодушны, постоянно пишут и звонят. Он утверждал, что это нормально и правильно для мужчины. Я поддакивал, не до конца понимая, это он серьезно или смеется надо мной. Выходило, что у него буквально отбоя нет от поклонниц, хотя внешностью он никак не напоминал Роберта Дауни — младшего, а харизмой Владимира Машкова. Ну что ж, всякое бывает…',
                'Мы подъехали к моему подъезду, я оплатил поездку и вышел. Дома позвонил другу, и мы долго обсуждали эту ситуацию.В целом большинство наших водителей добрые и веселые. Больше рассказов таксистов мне нравятся только истории, которые я слышу в автобусе.'
            ],
            rgbImg: { src: taxi2, orient: 'imgHor', order: 3 }
        }
    ],
    mainImg: taxi0,
    bgrImg: taxiBgr
};

export default taxi;
