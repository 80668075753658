const subMenu = [
    {
        id: '001',
        name: 'Создатели',
        link: 'creators'
    },
    {
        id: '002',
        name: 'Медиашкола',
        link: 'mediaschool'
    },
    {
        id: '003',
        name: '«Северный город»',
        title: 'Медиакомпания «Северный город»',
        link: 'gorod'
    }
];

export default subMenu;
