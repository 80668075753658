import cultureBgr from '../../assets/images/articles/bgr/16_culture_bgr.png';
import culture0 from '../../assets/images/articles/16_culture_0.png';
import culture1 from '../../assets/images/articles/16_culture_1.png';
import cultureMono from '../../assets/images/articles/16_culture_mono.png';
import cultureIcon from '../../assets/images/icons/culture_icon.png';

const culture = {
    id: '16',
    name: 'culture',
    cat: 'locations',
    icon: cultureIcon,
    geo: { top: 4, left: 21 },
    mainTitle: 'Городской центр культуры',
    chapters: [
        {
            title: 'Я пришла сюда в\u00A0три года и\u00A0больше не\u00A0уходила…',
            author: 'Поля Зелина',
            text: [
                'Как любой человек может закрыть глаза и с легкостью пройтись по собственной квартире, так я уверенно обойду весь ГЦК, ставший для меня одним из главных мест в родном городе. Сюда я обычно бежала с гитарой наперевес, вслух напевая песню или проговаривая слова сценария.',
                'Здесь все свое, знакомое: ступеньки, стены, пуфики, зеркала. Здесь все улыбаются мне как своей: вахтерши, гардеробщицы, билетеры. Здесь я чувствую себя частью системы, недоступной для человека, пришедшего на концерт, но такой важной для тех, благодаря кому этот концерт состоится.',
                'Большой зал — сердце ГЦК. В раннем детстве мне поручили вынести на сцену и подарить огромного плюшевого медведя гостям Таймырского фестиваля авторской песни. Мы с косолапым явно были в разных весовых категориях, но я мужественно выволокла его и вручила «Грушинскому трио». Помню слепящий свет софитов, жар от прожекторов и грохот аплодисментов. Помню, как пахла сцена — жженым пластиком, резиной и чем-то, что заставляет волноваться. Мне было три года, когда я вышла на эту сцену впервые. С тех пор я с нее и не уходила.',
                'Здесь я пела, играла на гитаре, читала стихи, танцевала, становилась лауреатом фестивалей и конкурсов. А еще сидела, лежала, кувыркалась, засыпала, пила чай и читала учебник по истории. ',
                'На этой сцене я практически жила: уходить с репетиций иногда приходилось далеко за полночь.',
                'Особой ценностью для меня обладает коридор с гримерными — место, куда не вхож простой зритель. В воздухе здесь всегда стоит едкий запах лака для волос. Кто-то репетирует, кто-то паникует, у кого-то сломался замок на платье, и все бегают, бегают, и ты тоже начинаешь бегать, с головой ныряя в предконцертную суматоху. Именно в этих условиях у меня по-настоящему загораются глаза. Чувствую, что я на своем месте. Чувствую себя счастливой.',
                'Переступая порог ГЦК, я всегда знаю, что будет интересно. За столько лет я испытала здесь весь спектр эмоций, попадала в передряги и веселые истории. Прямо на сцене у меня ломалась гитара и мне приносили другую. Перед выступлением случайно роняла медиатор, и мы толпой шарили по полу в темноте кулисы, в панике пытаясь найти его. А в один прекрасный день я сидела в зале, слушая коллег, и случайно пропустила момент, когда настала моя очередь. Так прямо из зала на сцену и выбежала. Я смотрела концерты, билеты на которые были раскуплены, из радиорубки, ведь еще с детства подружилась со «звукарями». Я случайно залетала в гримерные мэтров авторской песни и часами обсуждала с ними технику игры на гитаре…',
                'Всю свою юность я бегала здесь с друзьями сквозь бесконечные ряды бархатных кресел зала, исследовала жутковатые подсобные помещения, отчаянно пыталась открыть тяжелую дверь, ведущую на крышу, забегала в кабинет директора, играя в прятки. Не помню такого дня в ГЦК, когда бы я не смеялась заливистым искренним смехом. Здесь я нашла своих настоящих друзей и одного очень важного для меня человека. Это место вырастило меня такой, какая я есть сейчас, и я благодарна ему. В двух строках финальной песни одного из наших концертов умещается необъятная ценность ГЦК для меня и таких, как я:'
            ],
            monoImg: { src: cultureMono, orient: 'monoImgNormal' },
            rgbImg: { src: culture1, orient: 'imgHor', order: 3 }
        }
    ],
    ps:
        'Когда на сердце тяжесть и холодно в груди,\n' +
        'К ступеням гэцэкашным ты в сумерках приди…',
    mainImg: culture0,
    bgrImg: cultureBgr
};
export default culture;
