import gorodokBgr from '../../assets/images/articles/bgr/54_gorodok_bgr.png';
import gorodok0 from '../../assets/images/articles/54_gorodok_0.png';
import gorodok1 from '../../assets/images/articles/54_gorodok_1.png';
import gorodokMono from '../../assets/images/articles/54_gorodok_mono.png';
import gorodokIcon from '../../assets/images/icons/gorodok_icon.png';

const gorodok = {
    id: '54',
    name: 'gorodok',
    cat: 'symbols',
    icon: gorodokIcon,
    mainTitle: 'Снежный городок',
    chapters: [
        {
            title: 'Самый классный аттракцион — ледяная горка!',
            author: 'Дарья Березина',
            text: [
                'Однажды в канун Нового года — мне тогда едва исполнилось шесть лет — мама и папа пошли со мной на открытие городской елки. Я была в предвкушении счастья! Мне кажется, каждый норильский ребенок весь год ждет с нетерпением, когда его поведут за руку в мир ледяного веселья — снежный городок. У нас ведь нет парка аттракционов, поэтому ледяные горки и скульптуры — самое то! Раньше они размещались перед театром имени Маяковского, а теперь еще и в парке на Комсомольской улице, возле медиакомпании «Северный город».',
                'И вот мы дошли до огромной зеленой красавицы. К этому времени там уже столпились маленькие норильчане, громко играла праздничная музыка и началось выступление артистов. На улице было минус 30, дул сильный ветер, но я смотрела представление не отрывая глаз. Мне казалось, что все происходящее было настоящим волшебством. Я восхищалась всем: и танцевальными номерами, и разноцветным светом повсюду, и даже огромным количеством людей. Но  главное — я была безумно счастлива, что со мной рядом мои любимые родители.',
                'После мероприятия мы зашли в небольшой, но милый магазинчик, где на полочках стояли игрушки, имбирное печенье, фигурки Деда Мороза и еще много всего интересного. Мне купили большую игрушку и новогоднее лакомство в праздничной коробочке.',
                'К вечеру мы вернулись домой. Мама заварила земляничный чай, я вынесла свое печенье, и все принялись за чаепитие. Мы долго сидели, обсуждая, как прекрасно провели время и сколько положительных эмоций получили. Думаю, не мы одни.'
            ],
            monoImg: { src: gorodokMono, orient: 'monoImgNormal' },
            rgbImg: { src: gorodok1, orient: 'imgVert', order: 2 }
        }
    ],
    mainImg: gorodok0,
    bgrImg: gorodokBgr
};

export default gorodok;
