import petushokBgr from '../../assets/images/articles/bgr/20_petushok_bgr.png';
import petushok0 from '../../assets/images/articles/20_petushok_0.png';
import petushok1 from '../../assets/images/articles/20_petushok_1.png';
import petushokIcon from '../../assets/images/icons/petushok_icon.png';

const petushok = {
    id: '20',
    name: 'petushok',
    cat: 'locations',
    icon: petushokIcon,
    geo: { top: 19, left: 69 },
    mainTitle: 'Детский сад «Золотой петушок» в Талнахе',
    chapters: [
        {
            title: 'Как я был мажором',
            author: 'Иван Высоковских',
            text: [
                '«Царство снега и льда, ледяная пустыня, скрывающая подземные сокровища, небольшая точка на 69-й параллели северной широты…» — типичное описание моего города в сочинении какого-нибудь семиклассника, рассказывающего о своей малой родине. О моей малой родине. Пурга, холод, актировка — обязательные атрибуты норильского быта. И конечно, у любого жителя этой северной столицы, в очередной раз пробирающегося сквозь сугробы под завывания холодных потоков воздуха, есть заветное место, которое он вспоминает, чтобы согреть свое обветренное лицо и душу. Для многих моих ровесников это школа — некоторые даже называют ее вторым домом. Для меня же она никогда таковым не была. А вот детский садик — да! Давние дела, много лет пролетело, но ничего прекраснее моего «Золотого петушка», расположенного на улице Дудинской, 5 в районе Талнах, я до сих пор не видел.',
                'В первое время, переступая его порог, я старался поскорее спрятаться за маму или папу, настолько пугающей и дискомфортной казалась мне новая жизнь по сравнению с уютной домашней обстановкой. Но постепенно посещение садика стало приносить удовольствие. Утром, обгоняя  торопясь встретиться с новыми друзьями и игрушками. Даже нелюбимая ранее манная каша за общим завтраком съедалась наперегонки — так мы соревновались, кто быстрее вырастет большим и сильным.',
                'Затем, проворно натягивая куртки, толпились в раздевалке, нетерпеливо дожидаясь разрешения поскорее побежать на детскую площадку. Веселые шумные игры и маленькие состязания юных спортсменов заряжали энергией и возбуждали аппетит. Сияющие глаза на раскрасневшихся грязных мордашках умиляли каждого прохожего, который непременно улыбался, перекидывая нам через оградку улетевший на улицу мяч.',
                'Поглотив в один присест обед, мы скорее бежали к кроватям, чтобы по прошествии тихого часа еще полдня провести за любимыми играми. Вечером все прыгали в распахнутые родительские объятия и всю дорогу до квартиры рассказывали о свежих впечатлениях. А назавтра возвращались в детский сад, чтобы получить очередную порцию счастья.',
                'Такой радужной обстановке, конечно, очень способствовали два человека — мама и бабушка, по совместительству — воспитатель и няня в моей группе. Можно сказать, детский сад был городом, а я сыном мэра, которому в этом городе позволялось многое. Если честно, я по полной программе пользовался «служебным положением». В мои привилегии входила возможность пить чай и есть конфеты с воспитателями или играть в чужие игрушки без ведома владельцев, пока все мирно отсыпались в тихий час. А если я все-таки ложился спать, то после пробуждения бабушка обязательно радовала меня заранее припасенными сладостями и соком. Никто, разумеется, меня не трогал и не обижал. При этом я тоже не зарывался, не выпендривался и вел себя хорошо, ведь мама, если что, сразу спустила бы меня с небес на землю. В общем, мажорским положением я пользовался, но меру знал и отношения с товарищами по группе у меня были прекрасными.',
                'Бабушка уже вышла на пенсию, а мама несколько лет назад сменила место работы, да и сам я давно не наведывался в свою детскую обитель. Но почему-то совершенно уверен, что и по сей день каждый кирпичик, каждый уголок этого детского садика дышит любовью и живет счастьем малышей.'
            ],
            rgbImg: { src: petushok1, orient: 'imgVert', order: 4 }
        }
    ],
    mainImg: petushok0,
    bgrImg: petushokBgr
};

export default petushok;
